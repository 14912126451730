const middleware = {}

middleware['auth-admin'] = require('../middleware/auth-admin.js')
middleware['auth-admin'] = middleware['auth-admin'].default || middleware['auth-admin']

middleware['postRequest'] = require('../middleware/postRequest.js')
middleware['postRequest'] = middleware['postRequest'].default || middleware['postRequest']

middleware['postRequestHandler'] = require('../middleware/postRequestHandler.js')
middleware['postRequestHandler'] = middleware['postRequestHandler'].default || middleware['postRequestHandler']

middleware['redirect'] = require('../middleware/redirect.js')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

middleware['redirectRoot'] = require('../middleware/redirectRoot.js')
middleware['redirectRoot'] = middleware['redirectRoot'].default || middleware['redirectRoot']

middleware['stats'] = require('../middleware/stats.js')
middleware['stats'] = middleware['stats'].default || middleware['stats']

export default middleware
