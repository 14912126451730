import goTo from 'vuetify/es5/services/goto'
export default async function (to, from, savedPosition) {
  const headerHeight = document?.querySelector('#header')?.offsetHeight || 0
  if (to.params.toTop) {
    await goTo(0, { duration: 300, offset: 0, easing: 'easeInOutQuad' })
    return { x: 0, y: 0 }
  }
  if (to.hash && document?.querySelector?.(to.hash)) {
    goTo(to.hash, { duration: 300, offset: headerHeight, easing: 'easeInOutQuad' })
    return
  } else if (to.params.noScroll) {
    return
  }
  return new Promise(resolve => {
    this.app.$root.$once('triggerScroll', () => {
      if (to.hash && document?.querySelector?.(to.hash)) {
        // 判斷是否帶有hash && 頁面上找得到 #hash element
        resolve(goTo(to.hash, { duration: 300, offset: headerHeight, easing: 'easeInOutQuad' }))
      } else if (savedPosition) {
        resolve(goTo(savedPosition.y))
      } else {
        resolve(goTo(0))
      }
    })
  })
}
